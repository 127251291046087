.page-loader__wrapper {
  position: fixed;
  display: flex;
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.420);
}

.page-loader__wrapper--hidden {
  display: none;
}

.page-loader__icon {
  width: 32px;
  height: 32px;
  margin: auto;
  animation: rotate .69s linear infinite;

  svg {
    fill: #00BAFF;
  }
}
.font-loader {
  position: absolute;
  top: -100px;
  font-family: source-sans-basic, "Source Sans Pro", sans-serif;
  font-size: 1px;
  color: transparent;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-900 {
  font-weight: 900;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
